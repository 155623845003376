//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'

import { defineStore } from 'pinia'

export const useCountriesStore = defineStore('CountriesStore', {
  state: () => ({
    Countries: [] as any[]
  }),
  actions: {
    async fetchCountries() {
      if (this.Countries.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/countries')
          return (this.Countries = result.data)
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
